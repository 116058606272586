





















































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import VsSubscriptions from '../components/VsSubscriptions/Index.vue'
import VsCampaignOverview from '../components/VsCampaignOverview/Index.vue'
import VsCampaignActivity from '../components/VsCampaignActivity/Index.vue'
import VsAutomations from '../components/VsAutomations/Index.vue'
import VsModulesAndPopUp from '../components/VsModulesAndPopUp/Index.vue'
import VsPages from '../components/VsPages/Index.vue'
import VsNews from '../components/VsNews/Index.vue'
import VsCampaignCreateModal from '@/modules/campaigns/components/CreateCampaignModal/Index.vue'
import VsSelectImportList from '@/modules/lists/components/VsSelectImportList/Index.vue'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsTermsAndCondictions from '../components/VsTermsAndCondictions/Index.vue'
import { get, orderBy } from 'lodash'
import { AppModule } from '@/store/modules/app'
import { UserModule } from '@/store/modules/user'
import { getDashboardBanner } from '@/api/userapi/integrations'
import moment from 'moment'
import { createCart, createDriveToCart, getPricesAddable, getUserUpcomingInvoice } from '@/api/shop'
import { CartLineItemWithPrice, PriceWithProductRulesPreset } from '@/api/shop/types/shop'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'

@Component({
    name: 'DashboardIndex',
    components: {
        VsSubscriptions,
        VsCampaignOverview,
        VsCampaignActivity,
        VsAutomations,
        VsModulesAndPopUp,
        VsPages,
        VsNews,
        VsCampaignCreateModal,
        VsTermsAndCondictions,
        VsSelectImportList,
        VsContainer,
    },
})
export default class extends Vue {
    private showNotification = true
    private showModal = false
    private pricesAddable: PriceWithProductRulesPreset[] = []
    dashboardBanner: any = null
    userUpcomingInvoice: any = null

    get parsedNextPaymentAttempt () {
        if (!this.userUpcomingInvoice) return ''
        return moment.unix(this.userUpcomingInvoice.next_payment_attempt).format()
    }

    set parsedNextPaymentAttempt (val) {
        if (!this.userUpcomingInvoice) return
        if (!val) {
            this.userUpcomingInvoice.next_payment_attempt = null
            return
        }
        this.userUpcomingInvoice.next_payment_attempt = moment(val).unix()
    }

    get is4Dem () {
        return get(AppModule.consoleConf, 'is4Dem', false)
    }

    get shopUser () {
        return UserModule.shopUser
    }

    get user () {
        return UserModule.user
    }

    get hasShop () {
        return get(this.user, 'configuration.rules.shop', false)
    }

    get hasMocks () {
        return AppModule.hasMocks
    }

    get firstAcquisitionProduct () {
        return orderBy(
            this.pricesAddable.filter(el => el.product.metadata?.ruleGroup === 'acquisition' && el.rate.type === 'months'),
            'value',
            'asc',
        )[0]
    }

    async beforeMount () {
        await this.getUserUpcomingInvoice()
        await this.getDashboardBanner()
        await this.getPricesAddable()
    }

    private async getPricesAddable () {
        try {
            const resp = await getPricesAddable()
            this.pricesAddable = resp.data
        } catch (e) {
            this.pricesAddable = []
            console.log(e)
        }
    }

    async getDashboardBanner () {
        try {
            const resp = await getDashboardBanner()
            this.dashboardBanner = resp.data.dashboardBanner
        } catch (e) {
            console.log(e)
            this.dashboardBanner = null
        }
    }

    get userExpiredPolicy () {
        return UserModule.user.configuration.rules.expiredPolicy
    }

    get userPlan (): CartLineItemWithPrice | null {
        return this.shopUser?.subscription?.items.find(el => el.price.product?.strategy === 'baseSet') || null
    }

    get baseSetRuleGroup () {
        return get(this.userPlan, 'price.product.metadata.ruleGroup', '')
    }

    get showDriveToYearly () {
        if (
            !this.hasShop ||
            !this.baseSetRuleGroup ||
            !['communication', 'acquisition', 'professional'].includes(this.baseSetRuleGroup) ||
            get(this.userPlan, 'price.rate.type', '') !== 'months' ||
            get(this.shopUser, 'subscription.cancelAtPeriodEnd', false) ||
            !this.userUpcomingInvoice ||
            !this.userUpcomingInvoice.next_payment_attempt
        ) return false

        const endDate = moment.unix(this.userUpcomingInvoice.next_payment_attempt)
        const now = moment()
        const dayDiff = endDate.diff(now, 'days')
        return dayDiff <= 15 && dayDiff >= 0
    }

    get showDriveToUpgrade () {
        if (
            !this.hasShop ||
            !this.baseSetRuleGroup ||
            !['communication', 'acquisition'].includes(this.baseSetRuleGroup) ||
            get(this.userPlan, 'price.rate.type', '') !== 'years' ||
            get(this.shopUser, 'subscription.cancelAtPeriodEnd', false) ||
            !this.userUpcomingInvoice ||
            !this.userUpcomingInvoice.next_payment_attempt
        ) return false

        const endDate = moment.unix(this.userUpcomingInvoice.next_payment_attempt)
        const now = moment()
        const monthDiff = endDate.diff(now, 'months')
        return monthDiff < 9 && monthDiff > 6
    }

    get showDriveToTrial () {
        if (
            this.hasShop &&
            this.userExpiredPolicy === 'email_recharge_at' &&
            this.shopUser &&
            this.shopUser.trialAvailable
        ) {
            const startDate = moment(this.user.createdAt)
            const now = moment()
            const dayDiff = now.diff(startDate, 'days')
            return dayDiff <= 7 && dayDiff >= 0
        }
        return false
    }

    get showHappy () {
        const userCreatedDate = moment(this.user.createdAt)
        const startDate = moment('2024-06-30', 'YYYY-MM-DD')
        const endDate = moment('2024-10-31', 'YYYY-MM-DD')
        const today = moment()

        return (
            this.hasShop &&
            userCreatedDate.isSameOrAfter(startDate) &&
            today.isBetween(startDate, endDate, 'day', '[]') &&
            !get(this.shopUser, 'subscription', false) &&
            this.userExpiredPolicy === 'email_recharge_at'
        ) || (
            today.isBetween(startDate, endDate, 'day', '[]') &&
            this.userExpiredPolicy === 'last_login'
        )
    }

    get passwordModifiedAt () {
        return get(UserModule, 'user.passwordModifiedAt', '')
    }

    // @Watch('shopUser', { immediate: true, deep: false })
    private async getUserUpcomingInvoice () {
        try {
            const resp = await getUserUpcomingInvoice()
            this.userUpcomingInvoice = resp.data
        } catch (e) {
            this.userUpcomingInvoice = null
            console.log(e)
        }
    }

    private isTimeToUpdatePassword () {
        const passwordModifiedAt = this.passwordModifiedAt
        if (!passwordModifiedAt) return true
        const today = moment()
        const date = moment(passwordModifiedAt)
        const diff = today.diff(date, 'months')
        return diff >= 6
    }

    private redirectToAccount () {
        if (this.is4Dem) {
            this.$router.push('/account/security')
        } else {
            window.location.replace(`/app/user/account`)
        }
    }

    async createCart (type: 'yearly' | 'upgrade') {
        try {
            const resp = await createDriveToCart(type)
            this.trackCartCreatedMixpanelEvent(resp.data, 'DriveTo' + type.charAt(0).toUpperCase() + type.slice(1), false, 'years')
            this.$router.push({
                name: 'cart',
            })
        } catch (e) {
            this.$router.push({
                name: 'shop',
            })
            console.log(e)
        }
    }

    private async createTrialCart () {
        try {
            const resp = await createCart({
                items: [{
                    priceId: this.firstAcquisitionProduct._id,
                    quantity: 1,
                }],
            })
            this.saveSessionStorage(resp.data.id)
            this.trackCartCreatedMixpanelEvent(resp.data, 'DriveToTrial', true, 'months')
            this.$root.$vsToast({
                heading: 'Prodotto aggiunto al carrello',
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })

            this.$router.push({
                name: 'cart',
            })
        } catch (e) {
            console.log(e)
        }
    }

    private trackCartCreatedMixpanelEvent (cart: any, source: string, trial: boolean, interval: string) {
        if (UserModule.Mixpanel) {
            UserModule.Mixpanel.track(
                'CartCreated',
                {
                    distinct_id: this.user._id,
                    CartId: cart._id,
                    Trial: trial,
                    HasSubscription: !!(this.shopUser && this.shopUser.subscription),
                    Interval: interval,
                    Source: source,
                },
            )
        }
    }

    saveSessionStorage (idCart: string) {
        window.sessionStorage.setItem('trial-cart', JSON.stringify({
            idCart,
            trial: true,
        }))
    }
}
